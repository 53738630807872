import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import ExchangeIcon from '@shared/ui/icons/ExchangeIcon';
const ExchangeIconBadgeContainer = styled('div', { shouldForwardProp: propName => isPropValid(propName) })(props => ({
    display: 'inline-flex',
    position: 'absolute',
    top: props.top,
    left: props.left,
    borderRadius: '50%',
    border: `${props.borderSize}px solid #FFF`,
    backgroundColor: '#FFF',
}));
const ExchangeIconBadge = ({ type, size = undefined, borderSize = 3, top = -3, left = 0, }) => (_jsx(ExchangeIconBadgeContainer, { borderSize: borderSize, top: top, left: left, children: _jsx(ExchangeIcon, { type: type, size: size }) }));
export default ExchangeIconBadge;
