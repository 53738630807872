import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerColumn } from '@components/styled';
const Container = styled(ContainerColumn) `
    position: relative;
    width: auto;
    height: auto;
`;
const GetPositionStyles = ({ variant, childrenShape }) => {
    let distance = -4;
    let styles = '';
    if (childrenShape === 'square') {
        distance = -4;
    }
    else if (childrenShape === 'circle') {
        distance = -1;
    }
    if (variant === 'notification') {
        styles = `
            top: ${distance}px;
            right: ${distance}px;
        `;
    }
    else if (variant === 'online-status') {
        styles = `
            bottom: ${distance}px;
            right: ${distance}px;
        `;
    }
    return styles;
};
const Badge = styled.div `
    display: flex;
    align-items: center;
    width: 8px;
    height: 8px;
    gap: 8px;

    background-color: ${props => props.theme.customColors.portfolio.hover};
    border: 2px solid ${props => props.theme.customColors.portfolio.onHover};
    border-radius: 100px;

    position: absolute;
    ${props => GetPositionStyles(props)}
`;
const StatusBadge = ({ variant, children, childrenShape = 'square', }) => (_jsxs(Container, { children: [_jsx(Badge, { variant: variant, childrenShape: childrenShape }), children] }));
export default StatusBadge;
