var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { numberToFixed2 } from '@shared/lib/numbers';
import { ReactComponent as CaretUpIcon } from '@icons/wolfkit-solid/caret-up-solid.svg';
import { ReactComponent as CaretDownIcon } from '@icons/wolfkit-solid/caret-down-solid.svg';
import Icon from '@shared/ui/icons/Icon';
import Badge from '@shared/ui/badges/Badge';
const DisplayContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: 4,
}));
const IsIncomePositive = (income) => income >= 0;
const withPercentageIncomeBadge = (TypographyComponent) => {
    const PercentageIncomeBadge = (_a) => {
        var { percentageIncome, incomeSymbol = 'arrow', positiveColor = 'success', negativeColor = 'error', badgeVariant = 'plain', badgeSize = undefined, badgeEndAdornment = undefined, style = {}, displayInt = false } = _a, props = __rest(_a, ["percentageIncome", "incomeSymbol", "positiveColor", "negativeColor", "badgeVariant", "badgeSize", "badgeEndAdornment", "style", "displayInt"]);
        const displayValue = useMemo(() => (displayInt ?
            `${Math.abs(parseInt(`${percentageIncome}`, 10))} %` :
            `${numberToFixed2(Math.abs(percentageIncome))} %`), [percentageIncome, displayInt]);
        const color = useMemo(() => (IsIncomePositive(percentageIncome) ? positiveColor : negativeColor), [percentageIncome, positiveColor, negativeColor]);
        const startAdornment = useMemo(() => {
            if (incomeSymbol === 'arrow') {
                return (_jsx(Icon, { size: ['1em', '1em'], IconComponent: IsIncomePositive(percentageIncome) ?
                        CaretUpIcon :
                        CaretDownIcon }));
            }
            if (incomeSymbol === 'approximation') {
                return (_jsx(TypographyComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                , Object.assign({}, props, { children: "\u2248" })));
            }
            if (incomeSymbol === 'sign') {
                return (_jsx(TypographyComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                , Object.assign({}, props, { children: IsIncomePositive(percentageIncome) ? '+' : '-' })));
            }
            return undefined;
        }, [
            incomeSymbol,
            percentageIncome,
            props,
        ]);
        return (_jsx(Badge, { style: Object.assign({}, style), variant: badgeVariant, color: color, size: badgeSize, startAdornment: startAdornment, endAdornment: badgeEndAdornment, children: _jsx(DisplayContainer, { children: _jsx(TypographyComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                , Object.assign({}, props, { children: displayValue })) }) }));
    };
    return PercentageIncomeBadge;
};
export default withPercentageIncomeBadge;
