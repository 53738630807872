import { jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import getBadgeStyles from './styles';
const Container = styled.div((props) => (Object.assign(Object.assign({ display: 'flex', flexShrink: 0, alignItems: 'center', justifyContent: 'center' }, props.variant !== 'plain' && ({
    padding: getBadgeStyles(props.theme).bySizes[props.size].padding,
})), { gap: getBadgeStyles(props.theme).bySizes[props.size].gap, borderRadius: props.theme.shape.borderRadius })));
const Badge = ({ className = undefined, children = undefined, variant = 'filled', color = 'primary', size = 'medium', startAdornment = undefined, endAdornment = undefined, style = {}, }) => {
    const theme = useTheme();
    return (_jsxs(Container, { className: className, variant: variant, size: size, style: Object.assign({ color: getBadgeStyles(theme).byVariants[variant][color].color, backgroundColor: getBadgeStyles(theme).byVariants[variant][color].backgroundColor, border: getBadgeStyles(theme).byVariants[variant][color].border }, style), children: [startAdornment, children, endAdornment] }));
};
export default Badge;
